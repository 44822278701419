import { css } from 'styled-components';
import { media, viewportSizes } from '@tymate/margaret';
import { keys, toPairs } from 'lodash';

const colors = {
  green: '#00ae93',
  greenLight: '#46ad21',
  greenLighter: '#e0f8f0',
  greenDark: '#004d40',
  red: '#f65151',
  redLight: '#ffe0e0',

  // Couleurs Biben
  black: '#000000',
  blueDark: '#2A263E',
  blueDarkHover: 'rgba(42, 38, 62, 0.8)',
  pink: '#EF466C',
  pinkMid: '#F5939B',
  pinkLight: '#FAC7C7',
  pinkHover: '#C2566E',
  greyMid: '#7B7382',
  greyLight: '#B2ACB5',
  greyLighter: '#F5F5F5',
  greySecond: '#D9D9D9',
  blue: '#7095CC',
  blueLight: 'rgba(20, 113, 255, 0.3)',
  purple: '#9747FF',
  purpleDark: '#751F65',
  purpleLight: 'rgba(151, 71, 255, 0.3)',
  yellow: '#FFC11C',
  yellowLight: 'rgba(255, 193, 28, 0.3)',
  orange: '#FB8700',
  orangeLight: 'rgba(251, 135, 0, 0.3)',
  separator: 'rgba(0, 0, 0, 0.15)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',

  //Couleurs tags
  travaux: 'rgba(207, 34, 73, 1)',
  chargesCourantes: 'rgba(112, 149, 204, 1)',
  avanceTravaux: 'rgba(242, 107, 137, 1)',
  regulTravaux: 'rgba(239, 70, 108, 1)',
  regulChargesCourantes: 'rgba(42, 38, 62, 1)',
  repartIndiv: 'rgba(251, 135, 0, 1)',
  appelsExceptionnels: 'rgba(117, 31, 101, 1)',
  fondsPrevoyance: 'rgba(151, 71, 255, 1)',
  avanceTresorerie: 'rgba(52, 30, 101, 1)',
  avanceInsuffisance: 'rgba(117, 31, 101, 1)',

  success: 'rgba(70, 173, 33, 1)',
  error: 'rgba(246, 81, 81, 1)',
  required: 'rgba(246, 81, 81, 1)',

  gray: '#767676',
  grayLight: '#fcfcfc',
  grayDark: '#1e2020',

  white: '#fff',
};

export const theme = {
  ...colors,
  text: colors.blueDark,
  textLight: colors.greyMid,
  textDark: colors.black,

  primary: colors.pink,
  primaryLight: colors.pinkLight,
  primaryMid: colors.pinkMid,
  primaryDark: colors.blueDark,

  success: colors.green,
  info: colors.blueDark,
  error: colors.red,
  privacy: colors.blueDark,

  background: '#f5f5f5',

  backgroundText: colors.white,

  expensesTabHeader:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FFFFFF',

  //properties to color SVG icons
  filterPink:
    'invert(39%) sepia(23%) saturate(3494%) hue-rotate(312deg) brightness(96%) contrast(94%)',
  filterWhite:
    'invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%)',

  button: {
    borderRadius: '3px',
    alignY: 'center',
    alignX: 'center',
    fontWeight: 'bold',
    paddingHorizontal: 0,
    paddingVertical: 0,
    minWidth: 0,

    link: {
      color: colors.pink,
      colorDisabled: colors.greyLight,

      colorFocus: colors.pinkHover,
    },

    secondaryLink: {
      color: colors.blueDark,
      colorHover: colors.pink,
      colorDisabled: colors.greyLight,
    },

    primary: {
      background: colors.black,
      color: '#fff',
      boxShadow: `0 2px 2px ${colors.separator}`,
      borderRadius: '24px',

      backgroundHover: `${colors.pink}`,

      boxShadowFocus: `0 0 15px 2px ${colors.pink}`,

      backgroundDisabled: colors.greyLight,
      colorDisabled: '#fff',
      boxShadowDisabled: '0 0 0',

      paddingHorizontal: '30px',
      paddingVertical: '12px',
    },
    secondary: {
      background: '#fff',
      color: colors.blueDark,
      boxShadow: `0 0 0 1px ${colors.blueDark}, 0 1.5px 2px ${colors.separator}`,
      borderRadius: '24px',

      colorHover: colors.pink,
      boxShadowHover: `0 0 0 1px ${colors.pink}, 0 1.5px 2px ${colors.separator}`,

      boxShadowFocus: `0 0 15px 2px ${colors.pink}`,

      colorDisabled: colors.greyLight,
      boxShadowDisabled: `0 0 0 1px ${colors.greyLight}, 0px 1.5px 2px ${colors.separator}`,

      paddingHorizontal: '30px',
      paddingVertical: '12px',
    },
    tertiary: {
      background: '#fff',
      color: colors.pink,
      boxShadow: `0 0 0 1px ${colors.pink}, 0 1.5px 2px ${colors.separator}`,
      borderRadius: '24px',

      colorHover: colors.pinkHover,
      boxShadowHover: `0 0 0 1px ${colors.pinkHover}, 0 1.5px 2px ${colors.separator}`,

      boxShadowFocus: `0px 0px 12px 4px rgba(239, 70, 108, 1)`,

      colorDisabled: colors.pinkLight,
      boxShadowDisabled: `0 0 0 1px ${colors.pinkLight}, 0px 1.5px 2px ${colors.separator}`,

      paddingHorizontal: '16px',
      paddingVertical: '10px',
    },
    simple: {
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      boxShadow: '0 1.5px 2px rgba(0, 0, 0, 0.15)',

      colorHover: colors.pink,

      backgroundActive: colors.blueDark,
      colorActive: colors.white,

      colorFocus: colors.pink,

      borderRadius: '3px',
      paddingVertical: '12px',
      paddingHorizontal: '12px',
    },
    attachment: {
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '24px',
      boxShadow: '0 1.5px 2px rgba(0, 0, 0, 0.15)',
      color: colors.pink,

      colorHover: colors.pinkHover,

      colorActive: colors.pinkHover,

      boxShadowFocus: `0 0 15px 2px ${colors.pink}`,
      paddingVertical: '10px',
      paddingHorizontal: '16px',
    },
  },

  tabs: {
    margin: '',
    padding: '',
    alignY: 'center',
    alignX: 'center',
    tab: {
      direction: 'column',
      alignX: 'center',
      padding: '14px',
      fontWeight: 600,
      fontSize: '1rem',
      borderRadius: '3px',
      minWidth: '100%',
      maxWidth: '162px',
      whiteSpace: 'wrap',
    },
  },

  fonts: {
    title:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },

  mainNav: {
    position: 'left',
    width: '25rem',
    maxWidth: '100vw',
    widthTablet: '16rem',
    height: '55px',
    background: {
      mobile: '#ffffff',
      tablet: '#ffffff',
    },
    boxShadow: `0px 9px 18px rgba(0, 0, 0, 0.1);`,
    triggerColor: {
      expanded: 'rgba(0, 0, 0, 0.87)',
      collapsed: 'rgba(0, 0, 0, 0.87)',
    },
    sectionPanelHeaderColor: '#767676',
    sectionPanelHeaderColorExpanded: '#1E2020',
  },

  fontSizes: {
    h1Mega: {
      sizeMinRem: 1.75,
      sizeMaxRem: 2,
      lineHeightMin: 1.2,
      lineHeightMax: 1.1,
    },
    h1: {
      sizeMinRem: 1.375,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
    },
    h2: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
    },
    h3: {
      sizeMinRem: 1.125,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.4375,
      lineHeightMax: 1.25,
    },
    bodyLarge: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
    },
    body: {
      sizeMinRem: 0.875,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.3125,
      lineHeightMax: 1.5,
    },
    bodySmall: {
      sizeMinRem: 0.75,
      sizeMaxRem: 0.75,
      lineHeightMin: 1.35,
      lineHeightMax: 1.4,
    },
    legend: {
      sizeMinRem: 0.7,
      sizeMaxRem: 0.7,
      lineHeightMin: 1.25,
      lineHeightMax: 1.25,
    },
  },
};

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = viewportSizes.tablet,
  maxViewportWidth = viewportSizes.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${
    minViewportWidth / 16
  }em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

export const fontSize = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);

export const fontStyles = keys(theme.fontSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: css`
      ${fontSize[key]}
      ${lineHeight[key]}
    `,
  }),
  {},
);
